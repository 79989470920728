import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';

function Videos({ Dataurl }: { Dataurl: any }) {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="videosHead">My new releases</div>
      <div className="videosList">
        {Dataurl &&
          Dataurl.map((video: any) => {
            return (
              <div className="image-container" key={video.id}>
                <img
                  className="videoImg"
                  onClick={() => {
                    navigate(`/video/${video.title.replaceAll(' ', '-')}`);
                  }}
                  key={video.id}
                  src={
                    video.imgPath === ''
                      ? `https://img.youtube.com/vi/${video.id}/hqdefault.jpg`
                      : video.imgPath
                  }
                  alt=""
                />
                <div className="image-caption">{video.title}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Videos;
