import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';

interface ImagePath {
  path: string;
}
const logos: ImagePath[] = require('../assets/logosUrl.json');

function Logos() {
  return (
    <div>
      <Navbar />
      <div className="logos">
        {logos &&
          logos.map((logo: any) => {
            return (
              <img className="logoImg" key={logo.path} alt="" src={logo.path} />
            );
          })}
      </div>
    </div>
  );
}

export default Logos;
