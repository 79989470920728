import React from 'react';
import Navbar from './Navbar';

function Video({ id }: { id: string }) {
  return (
    <div>
      <Navbar />
      <div className="iframe">
        <iframe src={`https://www.youtube.com/embed/${id}`}></iframe>
      </div>
    </div>
  );
}

export default Video;
