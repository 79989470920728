import React from 'react';
import Navbar from './Navbar';

function Contact() {
  return (
    <div>
      <Navbar />
      <div className="contact">
        <a href="mailto:yoavzadikov1@gmail.com">yoavzadikov1@gmail.com</a>
        <a href="tel:+972524336335">+972524336335</a>
        <a href="https://wa.me/972524336335">
          <i className="fa fa-whatsapp"></i>
        </a>
      </div>
    </div>
  );
}

export default Contact;
