import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Homepage from './components/Homepage';
import Contact from './components/Contact';

import Dataurl from './assets/dataURL.json';

import './App.css';
import Logos from './components/Logos';
import Video from './components/Video';
import Videos from './components/Videos';

function App() {
  return (
    <div className="App">
      {' '}
      <Router>
        <Routes>
          {Dataurl &&
            Dataurl.map((video: any) => {
              return (
                <Route
                  key={video.id}
                  path={`/video/${video.title.replaceAll(' ', '-')}`}
                  element={<Video id={video.id} />}
                />
              );
            })}
          {/* <Route path="/" element={<Homepage Dataurl={Dataurl} />} /> */}
          <Route path="/" element={<Videos Dataurl={Dataurl} />} />
          <Route path="/Logos" element={<Logos />} />
          <Route path="/Contact" element={<Contact />} />

          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
