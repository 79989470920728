import React from 'react';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const navigate = useNavigate();
  return (
    <div>
      <nav className="navbar">
        {/* <button
          className="navbarBtn"
          onClick={() => {
            navigate('/');
          }}
        >
          Homepage
        </button> */}
        <button
          className="navbarBtn"
          onClick={() => {
            navigate('/');
          }}
        >
          Videos
        </button>
        <button
          className="navbarBtn"
          onClick={() => {
            navigate('/Logos');
          }}
        >
          Logos
        </button>
        <button
          className="navbarBtn"
          onClick={() => {
            navigate('/Contact');
          }}
        >
          Contact
        </button>
        <img src={require('./yoavlogo.png')} alt="ss" />
        <a
          target="_blank"
          href="https://www.instagram.com/yoavzadikov/"
          className="fa fa-instagram"
        />
      </nav>
    </div>
  );
}

export default Navbar;
